<template>
    <b-row>
        <b-col cols="12">
            <b-form-group :label="$t(labelPrefix+'contract')">
                <zw-model-selector-group v-model="inputVal.contract_id"
                                         name="contract_id"
                                         model-type="contract"
                                         validate="required"
                                         disable-label
                />
            </b-form-group>
        </b-col>
    </b-row>
</template>

<script>

import {mapGetters} from "vuex";
import ZwModelSelectorGroup from "@/components/ZwModelSelectorGroup";

export default {
    name: 'CreateCombineFile',
    components: {ZwModelSelectorGroup},
    props: {
        'value': [String, Number, Object],
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'settings.workflow.label.',
            contractSearchUrl: window.apiUrl + '/contract-search',
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getDocuments']),
        ...mapGetters('Workflow', ['getDetectionAttributes']),
        getDocumentsList() {
            let result = {}
            this.getDocuments().forEach(document => {
                result[document] = this.$t('invoices.document.' + document)
            })
            return result
        },
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
}
</script>